/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect,useState}  from "react"
import PropTypes from "prop-types"
import chroma from "chroma-js"

let textElement = React.createRef(); 
let interval;
function getWindow() {
  const { innerWidth: width, innerHeight: height, scrollY: scroll } = window;
  return {
    width,
    height,
    scroll
  };
}





class Intro extends React.Component {
  
  state = {
    left: 0,
    currentLeft:0,
    top: 0
  }

  constructor(props) {
     super(props);
  }
  componentDidMount() {
    let w = getWindow() ?? 0;
    this.setState({mouseLeft: 0, mouseTop: 0, window: w});
        
      interval = setInterval(() => {
        this.setState({left: this.state.left + 0.2 });
        if(this.state.left > 0) {

        this.setState({currentLeft: (this.state.left) / 1000})
        }

      },40)


      document.addEventListener('mousemove', (e) => {
          const ml =  e.pageX / w.width;
          this.setState({
            mouseLeft: ml,
            distanceFromCenter: -1 *(Math.abs((ml - 0.5) * 2) - 1)});
      });
  }
  
  elementClick(left) {
    return () => {

    }
  }
  
  render() {
  if(this.props.title && this.props.title != "") {
   let palette = chroma.scale(["rgb(255, 100, 250)", "rgb(60, 255, 100)", "rgb(255, 255, 100)"]); 
  //  let alpha = this.state.currentLeft > 0 ? 4 * (1 - this.state.currentLeft) : 1;
    let show = true;

    let current_sin = ((Math.sin(this.state.currentLeft * 20 - 1.4) + 1) / 2) * this.state.distanceFromCenter 
   + this.state.mouseLeft * (1 - this.state.distanceFromCenter)

    return(
      <div className="intro-outer" key={"outer"} style={{
           
        "position":"absolute",
          "overflow":"hidden",
          "top": 0,
          "height":"90vh",
          "left":0,"right":0  
  }}><div  key={"inner"} style={
        
        
    {
      "background":`linear-gradient(0deg, ${palette(current_sin ?? 0).alpha(0.0).hex()}, ${palette(1 - current_sin ?? 0).alpha(0).hex()})`,
      "top":-400 + (1 - current_sin) * 120,
    "bottom":-100,
    "overflow":"hidden",
    "left":- current_sin  * (textElement?.current?.scrollWidth - this.state.window?.width - 1000) -600,
    "right":-600,

    "-webkit-text-stroke-width": "20px",
    "-webkit-text-stroke-color": "rgba(248, 255, 108,1)",
    "fontSize":"120vh",


    "position":"absolute",
    "pointer-events":"none",
    "whiteSpace":"nowrap",
    "transition": "opacity 2s ease",
    "cursor":"pointer",
    "zIndex":"10"}}>
    <div onClick={this.props.onClick} ref={textElement} style={
      {
        "transform":`rotate(${ -(this.state.distanceFromCenter - 0.5) * 10}deg)`,
        "transformOrigin":"center center",
      "fontSize":"50vh",
      "-webkit-text-stroke-width": "3px",
      "-webkit-text-stroke-color": "rgb(114,243,255)",
      "float":"left",
      "marginTop":"60vh",
      "paddingLeft":730,
      "paddingRight":600,
      "color":"rgba(0,0,0,0)",
    }}>{this.props.title}</div>
  </div></div>
  )}
    return null
  }

}

Intro.defaultProps = {

}

Intro.propTypes = {

}

export default Intro
