import React, {useState} from "react"
import { Link } from "gatsby"
import Carousel from "./carousel"
import styled from "styled-components"
import { useForm, ValidationError } from '@formspree/react';

const StyledForm =  styled.form` 
max-width:1000px;
padding-top:10vh;
margin-left:auto; 
margin-right:auto;

input {
  width:48%;
  margin-bottom:20px;
}

button {
      background-color: rgb(60, 230, 200);
      border:none;
      margin:10px;
      display:inline-block;
      width:200px;
      margin-top:20px;
      
}

.actions {
  text-align:center;
}
`
const ContactContainer =  styled.div` 


@keyframes example {
  from {opacity:0;top:-100vh;bottom:100vh;}
  to {opacity:1;top:0vh;bottom:0vh;}
}
opacity: 0;
position:fixed;
z-index:1000;
animation-name: example;
animation-duration: 0.3s;
animation-fill-mode: forwards;
overflow-y:scroll;
background-color:rgba(255,255,255,1);
top:-100vh;left:0vh;bottom:100vh;right:0vh;
`


const ContactSuccess =  styled.div` 


opacity: 1;
position:fixed;
z-index:1000;
text-align:center;
padding:20px;
background-color:rgba(255,255,255,0.2);
top:0;left:0;right:0;bottom:0;
display:flex;
align-items: center;
justify-content: center;

>div {
  background-color:rgba(255,255,255,1);
  max-width:400px;
  padding:20px;
  button {
    margin-top:20px;
    font-size:14px;
  }
}
`


function ContactForm({children}) {
  
    const [complete, setComplete] =useState(false);
    const [active, setActive] = useState(false);
    const url = typeof window !== 'undefined' ? window.location.href : '';
    let [state, handleSubmit] = useForm("mgeraawo");

    if(active) {

      if (state.succeeded) {
        return <>
        <span onClick={() => {setActive(true)}}>{children}</span>
        <ContactSuccess><div><div>Thanks for your enquiry. We will respond shortly.</div><div class="actions"><button onClick={() => {setActive(false);setComplete(false);state.succeeded = false}} style={{backgroundColor:"rgba(0,0,0,0.1)"}}>Back to Website</button></div></div></ContactSuccess>
      </>
      }
       
      return <ContactContainer>
      <div>
  
     <StyledForm onSubmit={handleSubmit}>
            <p style={{
                textAlign:"center",
                borderBottom: "1px solid rgba(0,0,0,0.2)",
                paddingBottom:"20px",
                fontSize:"18px"
              }}>Please fill out the form below <br />or send me an email at <a href="mailto:frances@franceskeevil.com.au">frances@franceskeevil.com.au</a><br /> or call me on +61 411821550</p>
            <input type="text" placeholder="Your Name" id="name" name="name"></input>
            <input type="hidden" id="url" name="url" value={url}></input>
            <input placeholder="Your E-mail address"         id="email"
        type="email" 
        name="email"></input>
        <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
            <textarea style={{
                width:"100%",
                height:"200px",
                padding:"10px",
              
              }}  name="enquiry" id="enquiry" placeholder="Please write your enquiry here"></textarea>
              <ValidationError 
              prefix="Name" 
              field="name"
              errors={state.errors}
            />
            <div class="actions"><button onClick={() => {setActive(false)}} style={{backgroundColor:"rgba(0,0,0,0.1)"}}>Cancel</button><button  type="submit" >Submit</button></div>
      

    
        </StyledForm>
          </div>
          <div style={{
            opacity: active ? 1 : 0,
            position:"fixed",
            zIndex:1000,
            cursor:"pointer",
            backgroundColor:"rgba(255,255,255,1)",
            top:"20px",right:"30px"
          }} onClick={() => {setActive(false)}}>Go back to website &#x27F6;</div>
      </ContactContainer>
    }else {

      return <span onClick={() => {setActive(true)}}>{children}</span>
    }
}


ContactForm.defaultProps = {

}

ContactForm.propTypes = {

}

export default ContactForm