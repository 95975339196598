/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState}  from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Intro from "./intro-2"
import Header from "./header"
import Footer from "./footer"
import "../css/layout.css"

let intro_interval;
const Layout = ({ children,hero,invert,intro,title }) => {
  const [showIntro, setShowIntro] = useState(true);
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  let toggleIntro = function() {
    //setShowIntro(!showIntro);

    clearInterval(intro_interval);
  }
  let introF = function(show) {

      if(show) {
      return <Intro onClick={toggleIntro} />
      } 
  }
  return (
    <>
      <Header invert={invert} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Intro title={title} show={intro}/>
      {hero}
      <div
        style={{
          justifyContent:"center",
          width:"100%",
          boxSizing:"border-box",
          padding: `0 0rem`,
          display:"flex",
          overflow:"hidden"
        }}>
      <div
        style={{
          margin: `0`,
          maxWidth: 1800,
          width:"100%",
          padding: `35px 0`,
          boxSizing:"border-box",
        }}
      >
            
        <main           style={{
                    marginTop: `2rem`,
                  }}>{children}</main>
              
      </div>
    
      </div>
          {<Footer />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.element,
  invert: PropTypes.bool
}

export default Layout
