import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundSlider from 'gatsby-image-background-slider'
import PropTypes from "prop-types"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"

const header = (title) => {
  if(title) {
    return <h1 style={{"position":"relative","fontSize":"136px","color":"black"}}>{title}</h1>
  }
}

var SliderImage = styled.div`
margin: 10px none;
width: 100vw;
box-sizing:border-box;
position: relative;
grid-column-end: span ${props => props.span ? props.span : 3};
align-self: stretch;
height:${props => props.height ? props.height : "90vh"};
padding: 2.5vh;
transition: all 0.5s;
cursor: pointer;
background-size:cover;
  @media (max-width: 1000px) {
  height:40vh;
  margin-top:60px;
  }
`

const Carousel = ({ children,title,images,height,duration }) => {
     let d = duration ? duration : 30;
     let h = height ? height : "70vh";
     let imgs = images ? images : ["TREBILCOCK_OrangeFloweringGum100x150cm_HR.jpg","Australavians_Sally_Hook.jpg"];
     const settings = {
        dots: false,
        arrows: false,
        fade: true,
        speed: 5000,
        infinite: true,
        autoplay: true,
      }
  return (
    <>
    <Slider {...settings} className="overflow-hidden">
      {imgs.map((img) => <div><SliderImage height={height} style={{ backgroundPosition:"center center",backgroundImage: `url(${img})`}}>{title}</SliderImage></div>)}

     </Slider>
    </>)
}

// {imgs.map((img) => <div 
//   style={
//     {"fontSize":"110px",
//       "position":"relative",
//       "height":h,
//       "display":"flex",
//       "justifyContent":"center",
//       "alignItems":"center", "backgroundSize":"cover",}}>
//      {title}
//    </div>)}
// const Carousel = ({ children,title,images,height,duration }) => {
//   let d = duration ? duration : 30;
//   let h = height ? height : "70vh";
//   let imgs = images ? images : ["TREBILCOCK_OrangeFloweringGum100x150cm_HR.jpg","Australavians_Sally_Hook.jpg"];
// 
//   return (
//   <>
//     <main>{children}</main>
//     <div style={{"position":"relative","height":h,"display":"flex","justifyContent":"center","alignItems":"center"}}>
//     {header(title)}
//     <BackgroundSlider
//       className="masterhead"
//       query={useStaticQuery(graphql`
//         query {
//           backgrounds: allFile (filter: {sourceInstanceName: {eq: "images"}}){
//             nodes {
//               relativePath
//               childImageSharp {
//                 fluid (maxWidth: 2000, quality: 100){
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       `)}
//       initDelay={d} // delay before the first transition (if left at 0, the first image will be skipped initially)
//       transition={2} // transition duration between images
//       duration={d} // how long an image is shown
//       // specify images to include (and their order) according to `relativePath`
//       images={imgs}
// 
//       // pass down standard element props
// 
//       >          
//     </BackgroundSlider>
//     </div>
//   </>
// ) 
//  }

Carousel.propTypes = {
  title: PropTypes.string,
}

Carousel.defaultProps = {
  title: null,
}


export default Carousel